/* Home */

img.slide {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 100vh;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding:2px;
  background-color: black	;
}

.carousel-indicators .active{
  background-color: white;
}

.carousel-indicators {
  position: absolute;
  left: 0%;
  right: 60%;
}

.carousel-caption {
  position: absolute;
  font-family: "Source Sans Pro";
  left: 0%; 
  right: 60%;
  top: 20%;
}

.slide-name {
  font-size: 4vmin;
  white-space: nowrap;
  margin: auto;
  font-family: "Libre Baskerville";
}

.home-mobile-display {
  position: absolute;
  top: 15%;
  left: 15%;
  transform: translate(-15%, -15%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.intro {
  font-size: 4vmin;
  margin: auto;
  white-space: nowrap;
  font-family: "Source Sans Pro";
}

.view {
  position: relative; 
  margin: auto; 
  top: 20px;
  color: black;
}

/* Sidebar */

.sidebar {
  height: 100vh;
  width: 100%;
  background-color: #D2BBA0; 
  z-index: 999;
}

.headshot {
  position:relative;
  height: 75%;
  width: 75%;
  margin-top: 6vmin;
} 

.sidebar ul {
  list-style-type: none; 
  padding: 0px;
  text-align: center;
}

.sidebar ul li p {
  margin-top: 1vmin;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar ul li a {
  color: black;
}

.sidebar ul li {
  font-size: 2vmin;
}

.quick-links {
  margin-top: 8vmin;
  margin-bottom: 8vmin;
}

.quick-links li a {
  color: black;
  margin-top: 10%;
  margin-bottom: 10%;
}

.quick-links li a.active {
  font-weight: bold;
}

.icons{
  display: block;
  margin-top: 15vmin;
}

.sidebar-name {
  text-align: center;
  color: black;
  margin-top: 3vmin;
  margin-bottom: 1vmin;
  font-size: 3vmin;
  font-family: 'Libre Baskerville';
}

/* Main page general styling */

.general {
  text-align: center; 
  background-color: #483d3f; 
  font-family: "Source Sans Pro";
  color: #E9E9E9;
  font-size: 2vmin;
}

.main {
  padding-left: 15%;
  padding-right: 15%;
}

.general p {
  text-align: justify; 
  font-family: "Source Sans Pro";
}
@media (max-width: 768px) {
  .general p {
    font-size: 3vmin;
  }
}
@media (max-width: 480px) {
  .general p {
    font-size: 4vmin;
  }
}

.general h1 {
  font-family: 'Libre Baskerville';
  text-align: left; 
  font-size: 5vmin;
  color: #E9E9E9;
  margin-bottom: 10vmin;
  margin-top: 10vmin;
}
@media (max-width: 1200px) {
  .general h1 {
    margin-bottom: 8vmin;
    margin-top: 8vmin;
    font-size: 6vmin;
  }
}
@media (max-width: 480px) {
  .general h1 {
    font-size: 7vmin;
  }
}

/* About */

#about-header {
  margin-top: 5%;
}

/* Skills */

.skills {
  width: 100%;
  text-align: left;
}

.skill {
  overflow: hidden;
  text-overflow: ellipsis;
  /* Should have same font-size as general p */
  font-size: 2vmin; 
}
@media (max-width: 768px) {
  .skill {
    font-size: 3vmin;
  }
}
@media (max-width: 480px) {
  .skill {
    font-size: 4vmin;
  }
}

.skills-heading {
  display: block;
  font-weight: bold;
  font-size: 4vmin;
  margin-top: 10%;
  margin-bottom: 10%;
  white-space: nowrap;
}

#personal-skills {
  margin-top: 0%;
}

/* Projects */

.projects {
  color: black;
}

.projects p {
  color: black;
  font-size: 1.75vmin;
  text-align: center;
}
.projects h2 {
  color: black;
  font-weight: bold;
  font-size: 2.2vmin;
  text-shadow: 0;
  padding: 0;
  border: 0;
}
@media (max-width: 768px) {
  .projects p {
    font-size: 1.75vmin;
  }
  .projects h2 {
    font-size: 2.2vmin;
  }
}
@media (max-width: 480px) {
  .projects p {
    font-size: 3vmin;
  }
  .projects h2 {
    font-size: 4vmin;
  }
}

#view-link {
  font-weight: bold;
  color: black;
  text-align: center;
  margin-bottom: 0%;
  margin-top: 0%;
  padding: 0px; 
}
/* @media (max-width: 768px) {
  #view-link {
    font-size: 4vmin;
  }
} */
@media (max-width: 480px) {
  #view-link {
    font-size: 4vmin;
  }
}

.preview {
  box-shadow: 0 3px 6px rgba(0,0,0,0.32), 0 3px 6px rgba(0,0,0,100);
  align-self: center;
}

.card {
  margin-left: 5%;
  margin-right: 5%;
}

/* Work Experience */

#work {
  margin: auto;
  margin-bottom: 10%;
  width: 100%;
  height: auto;
}

#work-experience {
  margin-bottom: 10%;
}

.work-logo {
  border-radius: 15%;
  height: auto;
  width: 100%;
  overflow: hidden;
}

#work-title {
  text-align: center;
  font-size: 2.4vmin;
  font-weight: bold;
  font-family: "Source Sans Pro";
  margin-top: 1vmin;
  margin-bottom: 1vmin;
}
@media (max-width: 768px) {
  #work-title {
    font-size: 5.5vmin;
  }
}

.work-heading {
  font-size: 2.2vmin;
}
@media (max-width: 768px) {
  .work-heading {
    font-size: 4vmin;
  }
}

#work-description {
  margin-top: 7%;
  text-align: justify;
}

/* Contact Form */

#message-box {
  height: 15vmin;
  margin: auto;
  margin-top: 1%;
}
@media (max-width: 480px) {
  #message-box {
    height: 30vmin;
  }
}


#email-box {
  margin: auto;
}

.submit-button {
  margin-top:1%;
  margin-bottom: 10%;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  border: none;
  color: black;
  font-weight: 700;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: .5s ease all;
}
.submit-text {
  margin: auto;
  margin-top:3%;
  margin-bottom: 10%;
}
@media (max-width: 480px) {
  #message-box {
    height: 40vmin;
  }
  .submit-text {
    font-size: 4vmin;
  }
}

.fa-circle {
  font-size: 10px;
  margin-right: 1vmin;
} 

/* Media Queries */
@media screen and (max-width: 991px) { /* 991 is home img width */
  .sidebar {
      display: none !important;
  }

  .main {
    padding-left: 10%;
    padding-right: 10%;
  }

  #work {
    padding-right: 0; 
  }
}